<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Refresh Page 2"/>
    <!--EOC-->
    <p>Increase following number everytime when want to test if refresh is working.</p>
    <p>9</p>
    <p>Javascript Page 1 Version {{javascriptFile[0].version}}<br/>Javascript Page 2 Version {{javascriptFilePage2[0].version}}</p>
    <Refresh/>
    <TestRefresh2/>
    <div><v-btn class="mb-1" @click="$router.push({name:'PageServiceTestingRefresh'})">Back to Page 1</v-btn></div>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[components]
import Refresh from "@/services/testing/components/PageServiceTestingRefreshPage2/Refresh";
//EOC
//BOC:[config]
import configRefresh from "@/services/testing/config/refresh";
import configRefreshPage2 from "@/services/testing/config/refreshPage2";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    Refresh,
  },
  computed: {
    ...mapState({
      //
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    javascriptFile: configRefresh,
    javascriptFilePage2: configRefreshPage2,
  }),
  watch: {
    //
  },
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Testing",
      to: { name: "PageServiceDashboard", params: { serviceKey: "testing" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "RefreshPage2",
      to: { name: "PageServiceTestingRefreshPage2" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>